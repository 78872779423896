import React, { component } from 'react';
import Button from '../button/index';
import Modal from '.';
import { ModalBody, ModalFooter, ModalTitle } from './styles';

class ModalNew extends React.Component {
    constructor(props) {
        super(props);
    }

    styles = {
        control: provided => ({
            ...provided,
            padding: "15px"
        })
    };

    updateOk = () => {
        this.props.onHide();
    }



    render() {
        return (
            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
                width='60%'
            >
                <ModalTitle> TERMOS E CONDIÇÕES GERAIS DE USO </ModalTitle>

                <ModalBody>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', paddingLeft: '40px', paddingRight: '40px' }}>
                        {/* <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>TERMOS E CONDIÇÕES GERAIS DE USO</p> */}
                        <p style={{ textAlign: 'justify', fontWeight: '300', lineHeight: '1.5' }}>
                            Para garantir a todos os usuários a melhor experiência possível, nós, da <b>AGRORECEITA</b>, desejamos construir com vocês USUÁRIOS uma relação pautada na confiança e no respeito. Por isso, abaixo estão descritos os termos e condições gerais de uso que regulam a utilização do Sistema <b>AGRORECEITA</b>.
                            Antes de começar a usar os Serviços do Sistema, solicitamos que leia atentamente e compreenda os termos a seguir. Ao aceitá-los, você concordará inteiramente com as disposições aqui estabelecidas, independentemente de usar o Sistema de forma parcial ou apenas para testes. Isso inclui o consentimento
                            à <b>AGRORECEITA</b> para acessar, coletar, usar, armazenar, processar e aplicar medidas de proteção às suas informações, indispensáveis para o funcionamento das funcionalidades oferecidas pelo Sistema. Se você não concordar com algum dos termos apresentados, por favor, interrompa imediatamente o uso do Sistema.<br />
                            <br /><b style={{ fontWeight: '700' }}>CAPÍTULO I- CONDIÇÕES GERAIS</b><br />
                            <br />
                            <b>1.1.</b> O presente Termo e Condições Gerais de Uso (denominado somente “Termo de Uso”) é um contrato de adesão firmado entre a <b>CONTRATANTE</b>, pessoa física ou jurídica, e a AGRORECEITA TECNOLOGIA AGRÍCOLA E AMBIENTAL LTDA, pessoa jurídica de direito privado, devidamente inscrita no CNPJ sob o nº 44.098.901/0001-33 e Inscrição Estadual Isenta, situada na Rua Rosa Lotfi de Almeida Bueno nº 155,  Vila Nastri II, na cidade de Itapetininga, Estado de São Paulo, CEP 18.206-390, doravante <b>AGRORECEITA</b>.
                            <br /><b>1.2.</b> Este Termo de Uso vincula todas as atividades desenvolvidas e serviços oferecidos por meio do Sistema <b>AGRORECEITA</b> (doravante denominada “Sistema”), que engloba seus subdomínios, aplicativos, programas e demais extensões, podendo incluir os meios físicos associados, bem como quaisquer materiais impressos e qualquer documentação online ou eletrônica, disponibilizados neste ato por meio do website <a href="https://agroreceita.com.br/" target="_blank" >https://agroreceita.com.br/</a> e <a href="https://sistema.agroreceita.com.br/" target="_blank" >https://sistema.agroreceita.com.br/</a><br />
                            <br /><b style={{ fontWeight: '700' }}>CAPÍTULO II- DAS DEFINIÇÕES</b><br />
                            <br />
                            <b>2.1.</b> Ficam definidas as seguintes expressões utilizadas neste termo:
                            <br /><b>2.1.1.</b> Termo de Uso: significa este instrumento que regerá a relação comercial entre a <b>CONTRATANTE</b> e a <b>AGRORECEITA</b>, especificando as obrigações e os direitos estabelecidos em comum acordo entre as partes.
                            <br /><b>2.1.2.</b> Adesão e aceite: significa que pelo presente instrumento, a <b>CONTRATANTE</b> e os seus Usuários ficam cientes e concordam que, ao utilizarem a Plataforma, automaticamente, fazem adesão e concordam em se submeterem às condições do presente Termo de Uso.
                            <br /><b>2.1.3.</b> Usuários: os usuários são todas as pessoas, físicas ou jurídicas, que se utilizam da Plataforma.
                            <br /><b>2.1.4.</b>  Dados do cliente: significa qualquer dado ou material fornecido ou enviado pelo <b>CONTRATANTE</b> para o Sistema durante a utilização do <b>AGRORECEITA</b>.
                            <br /><b>2.1.5.</b> Administrador do Sistema: usuário designado pelo <b>CONTRATANTE</b>, que está autorizado a criar contas de usuário e administrar o Sistema.
                            <br /><b>2.1.6.</b> <b>AGRORECEITA</b>: O nome do Sistema, cujos termos de utilização ora se definem, composta por ferramentas para emissão e gerenciamento de receituários agronômicos.
                            <br /><b>2.1.7.</b> Sistema <b>AGRORECEITA</b>: é o website que será acessado através do endereço  <a href="https://sistema.agroreceita.com.br" target="_blank" >https://sistema.agroreceita.com.br</a> e que possibilita a interação com as funcionalidades da ferramenta.
                            <br /><b>2.1.8.</b> Receituário Agronômico: Documento obrigatório para compra e venda de defensivos agrícolas, de uso exclusivo e sob responsabilidade da <b>CONTRATANTE</b>.
                            <br /><b>2.1.9.</b> Ficha de Emergência: Documento utilizado para o transporte de produtos perigosos, comercializados no mercado nacional, de uso e sob responsabilidade da <b>CONTRATANTE</b>.
                            <br /><b>2.1.10.</b>FDS (FISPQ): Documento obrigatório para os empregadores que fabricam, utilizam, movimentam ou transportam produtos químicos,  de uso e sob responsabilidade da <b>CONTRATANTE</b>.
                            <br /><b>2.1.11.</b> Banco de Dados: Consiste na liberação de uso do banco de dados das bulas dos defensivos agrícolas, registrados no Brasil, para emissão do receituário agronômico. E da liberação do uso  do banco de dados das fichas de emergência para o transporte e das FDS (FISPQs) para impressão.
                            <br /><b>2.1.12.</b>Atualizações: Consiste na atualização do banco de dados do sistema contratado.
                            <br /><b>2.1.13.</b> Estação de Trabalho: É o computador devidamente selecionado pela <b>CONTRATANTE</b> para acesso ao Sistema.<br />
                            <br /><b style={{ fontWeight: '700' }}>CAPITULO III- DA LICENÇA DE USO DA PLATAFORMA</b><br />
                            <br />
                            <b>3.1. </b>O presente Termo de Uso concede ao <b>CONTRATANTE</b> uma licença revogável, não exclusiva e intransferível para uso do Sistema <b>AGRORECEITA</b>, não permitindo o acesso à outras ferramentas, além daquelas originalmente localizadas na mesma. Em hipótese alguma, a <b>CONTRATANTE</b> terá acesso ao código fonte desta ou das demais Plataformas de propriedade intelectual da <b>AGRORECEITA</b>. A utilização do Sistema deverá seguir as especificações previstas neste Termo de Uso, portanto a <b>CONTRATANTE</b> não poderá utilizar e nem permitir o seu uso de forma diferente.
                            <br /><b>3.2. </b>Para a utilização e acesso às funcionalidades do Sistema <b>AGRORECEITA</b>, a <b>CONTRATANTE</b> deverá efetuar o cadastro no site e a adesão do presente Termo de Uso, de acordo com o plano contratado. A partir daí, uma conta será criada e o usuário administrador poderá cadastrar quantos usuários forem necessários.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO IV - DO ACORDO DE NÍVEL DE SERVIÇO (SERVICE LEVEL AGREEMENT - SLA)</b><br />
                            <br />
                            <b>4.1.</b> A <b>AGRORECEITA</b> compromete-se a prestar serviços de suporte técnico em horário comercial das 8h às 18h, de segunda a sexta feira e aos sábados das 8h às 12h, exceto feriados.
                            <br /><b>4.2.</b> O serviço de suporte técnico consistirá em assessoria, para solução de dúvidas de caráter exclusivamente operacional dos usuários do Sistema licenciado, às pessoas habilitadas e devidamente autorizadas pela <b>CONTRATANTE</b>, e que será prestado pela <b>AGRORECEITA</b>,  de maneira remota, nos horários disponibilizados acima, limitando-se ao período de horário comercial.
                            <br /><b>4.2.1.</b> O suporte não contemplará, contudo, atendimento ao usuário ou soluções para problemas com infraestrutura e servidores da <b>CONTRATANTE</b>.
                            <br /><b>4.3.</b> Os serviços de suporte serão prestados através da abertura de chamados pela <b>CONTRATANTE</b>, através dos seguintes meios de contato:
                            WhatsApp: (15) 98165-7739;
                            Telefone: (15) 3272-9780;
                            E-mail: suporte@agroreceita.com.br.
                            <br /><b>4.4.</b> Nas situações de incidentes ou quando a <b>CONTRATANTE</b> necessitar de apoio, a <b>CONTRATADA</b> oferecerá suporte que funcionará conforme tabela na cláusula 4.5 e 4.7.
                            <br /><b>4.4.1.</b> Para fins de interpretação da presente cláusula, entende-se “impacto” como qualquer falha ou inoperância do sistema, originado da tecnologia disponibilizada pela <b>AGRORECEITA</b>.
                            <br /><b>4.4.2.</b> Não serão, contudo, considerados incidentes eventuais dúvidas acerca de como o Sistema ou o processo de negócio funcionam, ou inoperâncias ocasionadas pela rede da <b>AGRORECEITA</b> ou da rede dos usuários do Sistema, os quais são, desde já, qualificados como fatores externos e alheios à intervenção da <b>AGRORECEITA</b>.
                            <br /><b>4.5.</b> O suporte será remoto, devendo os chamados de suporte serem abertos conforme canal disponibilizado pela <b>AGRORECEITA</b>, obedecendo aos seguintes critérios:<br /><br />
                            <div style={{ overflowX: 'auto' }}>
                                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>*Impacto no sistema</th>
                                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Descrição do Impacto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}><b>Muito Alto</b></td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                Serviço que, quando não disponível, pode causar prejuízos monetários ou de patrimônio. Impossível seguir trabalhando sem solução.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}><b>Alto</b></td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                Serviço que, quando não disponível, pode gerar graves falhas em ativos ou outros serviços. O trabalho é possível de ser realizado sem a solução, mas com grave perda de eficiência ou recursos.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}><b>Médio</b></td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                Serviço que, quando não disponível, causa um impacto moderado ao processo da empresa. O trabalho ainda é possível de ser realizado com pouca interferência sem solução.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}><b>Baixo</b></td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                Serviço que, quando não disponível, causa pouco ou nenhum impacto ao processo da empresa. O trabalho é totalmente possível de ser realizado sem solução.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}><b>Muito Baixo</b></td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>
                                                Serviço que, quando não disponível, não causa impacto algum à corporação. O trabalho é totalmente possível de ser realizado sem solução.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p style={{ fontSize: '12px', fontStyle: 'italic', marginTop: '10px' }}>
                                    *Para fins de interpretação, entende-se “impacto” como qualquer falha ou inoperância do sistema, originada da tecnologia disponibilizada pelo <b><b>AGRORECEITA</b></b>. Não serão, contudo, considerados incidentes eventuais dúvidas acerca de como o Sistema ou o processo de negócio funcionam, ou inoperâncias ocasionadas pela rede <b><b>AGRORECEITA</b></b> ou rede dos usuários do Sistema, os quais são qualificados como fatores externos e alheios à intervenção do <b><b>AGRORECEITA</b></b>.<br /><br />
                                </p>
                            </div>
                            <br /><b>4.6.</b> A <b>CONTRATANTE</b> deve detalhar ao máximo possível o problema, ou dúvida relacionada ao serviço contratado. Exemplos podem ser mencionados para facilitar o entendimento e agilizar a resolução, enviar prints de tela, links ou qualquer outro material pode ser útil.
                            <br /><b>4.7.</b> A previsão do tempo para solução poderá sofrer alteração após análises efetuadas para a solução do problema e em situações que exijam o envolvimento de outras equipes (equipe de desenvolvimento, entre outras).<br /><br />
                            <div style={{ overflowX: 'auto' }}>
                                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Prioridade</th>
                                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Tempo de resposta</th>
                                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Tempo para solução</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}><b>Muito Alto</b></td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>5 horas úteis</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>5 dias úteis</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}><b>Alto</b></td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>10 horas úteis</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>10 dias úteis</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}><b>Médio</b></td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>15 horas úteis</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>15 dias úteis</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}><b>Baixo</b></td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>25 horas úteis</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>30 dias úteis</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid black', padding: '8px' }}><b>Muito Baixo</b></td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>40 horas úteis</td>
                                            <td style={{ border: '1px solid black', padding: '8px' }}>40 dias úteis</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br /><b>4.8.</b> A <b>CONTRATANTE</b> declara estar ciente de que a <b>AGRORECEITA</b> não garante o suporte necessário por falhas na conexão, link de internet, hardware, e demais impossibilidades de acesso que sejam de responsabilidade da <b>CONTRATANTE</b>, de clientes, parceiros, operadoras de telecomunicações ou qualquer outro terceiro.
                            <br /><b>4.9.</b> A <b>AGRORECEITA</b> também não garante o suporte necessário por mau uso do Sistema ou práticas inadequadas por parte de profissionais da <b>CONTRATANTE</b>, caso a utilização do Sistema se dê em desacordo com as instruções da <b>AGRORECEITA</b>.
                            <br /><b>4.10.</b> O suporte não incluirá qualquer forma de atendimento aos clientes finais da <b>CONTRATANTE</b>, de modo que todas as solicitações de suporte deverão ser feitas por funcionários ou prepostos da <b>CONTRATANTE</b>, devidamente identificados e capacitados para operar o Sistema.
                            <br /><b>4.11.</b> A <b>AGRORECEITA</b> pode realizar atividades de manutenção programada com aviso prévio ao <b>CONTRATANTE</b>. A <b>AGRORECEITA</b> fará esforços razoáveis para agendar a manutenção durante as horas não comerciais. O <b>CONTRATANTE</b> será avisado em até 05 (cinco) dias úteis.
                            <br /><b>4.12.</b> Em caso de manutenção de emergência, a <b>AGRORECEITA</b> fará todos os esforços razoáveis para minimizar o tempo de inatividade e fornecer notificação rápida ao <b>CONTRATANTE</b>.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO V- DO PREÇO E FORMA DE PAGAMENTO DO PREÇO, FORMA DE PAGAMENTO E PLANOS DE ASSINATURA</b><br />
                            <br />
                            <b>5.1. </b>Para avaliação do Sistema <b>AGRORECEITA</b>, a <b>CONTRATANTE</b> terá um período de testes gratuitos, contados a partir do preenchimento do cadastro e aceitação do presente Termo de Uso. Portanto, durante este período não haverá cobrança.
                            <br /><b>5.2.</b> Encerrado o período de testes, a CONTRATANTE poderá optar por uma das modalidades de contratação da Plataforma AGRORECEITA:
                            <ul style={{ marginLeft: '40px' }}>
                                <li>
                                    <b>Plano Mensal:</b> pagamento recorrente mensal;
                                </li>
                                <li>
                                    <b>Plano Anual:</b> pagamento antecipado de 12 (doze) meses;
                                </li>
                                <li>
                                    <b>Plano Pré-Pago:</b> aquisição de créditos com validade indeterminada.
                                </li>
                            </ul>
                            <br /><b>5.3.</b> No plano mensal, a CONTRATANTE pagará mensalmente à AGRORECEITA o valor correspondente ao plano de licenciamento contratado, com emissão mensal de Nota Fiscal e boleto.
                            <br /><b>5.4.</b> No plano anual, a contratação terá duração de 12 (doze) meses, com pagamento antecipado à vista ou parcelado. A renovação será automática por igual período, salvo manifestação da CONTRATANTE com pelo menos 30 (trinta) dias de antecedência. Após emissão da Nota Fiscal de renovação, não será possível cancelamento ou reembolso.
                            <br /><b>5.5.</b> O cancelamento antecipado do plano anual não gera direito à devolução dos valores pagos, salvo acordo expresso entre as partes.
                            <br /><b>5.6.</b> No plano pré-pago, a CONTRATANTE adquire créditos para uso da Plataforma AGRORECEITA, que <b>não expiram</b> e ficam disponíveis conforme a demanda.
                            <br /><b>5.7.</b> Os créditos somente serão liberados após a confirmação do pagamento integral. A AGRORECEITA emitirá Nota Fiscal correspondente ao valor adquirido no momento da compra dos créditos.
                            <br /><b>5.8. </b> Não haverá cobrança recorrente para clientes no plano pré-pago, tampouco renovação automática.
                            <br /><b>5.9. </b> Caso a <b>CONTRATANTE</b> opte por outro plano de licenciamento, no decorrer da vigência do presente instrumento, os valores serão alterados de acordo com o respectivo plano escolhido.
                            <br /><b>5.10. </b> A <b>AGRORECEITA</b> enviará à <b>CONTRATANTE</b> nota fiscal e boleto bancário no e-mail cadastrado do Sistema <b>AGRORECEITA</b> no próximo dia útil após a contratação do plano escolhido.
                            <br /><b>5.11. </b> Na ocorrência de atraso no pagamento, haverá incidência de multa de 2% (dois por cento) ao mês sob o valor da fatura, além de juros mensais de 1% (um por cento).
                            <br /><b>5.12. </b> Na hipótese de atraso por prazo superior a 15 (quinze) dias corridos, fica desde já acordado que a <b>AGRORECEITA</b> poderá suspender a execução do objeto deste Termo de Uso até que ocorra o adimplemento do valor em aberto.
                            <br /><b>5.13. </b> Ainda, no caso de atraso superior a 05 (cinco) dias, a cobrança será feita pelo Departamento Jurídico da <b>AGRORECEITA</b> e, além da incidência dos juros e multa acima citados, fica autorizado o protesto do boleto bancário e a cobrança de 20% (vinte por cento) de honorários advocatícios sobre o valor devido, além de custas judiciais ou extrajudiciais, conforme o caso.
                            <br /><b>5.14. </b> A assinatura referente ao plano contratado será reajustada automaticamente a cada 12 meses, contados a partir da data da adesão e aceite na Plataforma, pela variação do IGP-M (FGV), IPCA ou Taxa Selic, a critério da <b>AGRORECEITA</b>. Na ausência desses índices serão aplicados quaisquer outros índices inflacionários, determinados pelo Governo Federal, que venham a substituí-los.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO VI- DA VIGÊNCIA</b><br />
                            <br />
                            <b>6.1.</b> Este Termo de Uso entra em vigor a partir da adesão da CONTRATANTE à Plataforma AGRORECEITA e vigorará conforme o plano contratado:
                            <ul style={{ marginLeft: '40px' }}>
                                <li>
                                    <b>Plano Mensal:</b> vigência por prazo indeterminado, podendo ser rescindido a qualquer momento por qualquer das partes, mediante aviso prévio e expresso de 30 (trinta) dias, sem que isso implique em pagamento de multa ou indenização. Caso esse prazo não seja respeitado, a CONTRATANTE deverá pagar o equivalente a uma mensalidade.
                                </li>
                                <li>
                                    <b>Plano Anual:</b> vigência de 12 (doze) meses, com renovação automática. O cancelamento deverá ser solicitado com pelo menos 30 (trinta) dias de antecedência à renovação. Após emissão da Nota Fiscal, não será possível cancelamento ou estorno.
                                </li>
                                <li>
                                    <b>Plano Pré-Pago:</b> vigência indeterminada, válida enquanto houver créditos disponíveis. A CONTRATANTE poderá cessar o uso da Plataforma a qualquer momento, sem necessidade de aviso prévio e sem reembolso dos créditos adquiridos.
                                </li>
                            </ul>
                            <br /><b>6.2.</b> Este Termo de Uso entra em vigor a partir da data do seu aceite pela <b>CONTRATANTE</b>, podendo ser modificado unilateralmente pela <b>AGRORECEITA</b>, a qualquer momento sem qualquer ônus, mediante comunicado através do próprio site ou envio de mensagem para o e-mail indicado pela <b>CONTRATANTE</b>.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO VII- DA EXTINÇÃO CONTRATUAL</b><br />
                            <br />
                            <b>7.1.</b> Este Contrato será automaticamente extinguido, sem prejuízo da cobrança de perdas e danos, independentemente de aviso prévio e de qualquer formalidade, se qualquer PARTE:
                            <br /><b>7.1.1.</b> Descumprimento de qualquer das condições e obrigações previstas neste instrumento ou na legislação aplicável, se não sanado em 30 (trinta) dias corridos da data do recebimento de comunicação neste sentido, a critério da PARTE prejudicada;
                            <br /><b>7.1.2.</b> Tiver decretada sua falência ou ingressar em procedimento de recuperação judicial que impeça o cumprimento do presente contrato;
                            <br /><b>7.1.3.</b> Caso fortuito ou força maior que perdure por mais de 30 (trinta) dias consecutivos ou intercalados por prazo inferior a 1 (um) ano e torne comprovadamente impossível o cumprimento do contrato;
                            <br /><b>7.1.4.</b> Atraso injustificado no pagamento previsto na Cláusula 4.1., por prazo superior a 15 (quinze) dias por culpa da <b>CONTRATANTE</b>;
                            <br /><b>7.1.5.</b> Se forem praticados atos que venham a causar prejuízos à imagem pública da contraparte, sem prejuízo da apuração de perdas e danos.
                            <br /><b>7.1.6.</b> Revelação ou utilização de qualquer informação confidencial recebida por parte da contraparte, fora dos propósitos específicos da prestação de serviços contratados.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO VIII- DAS OBRIGAÇÕES DA <b>CONTRATANTE</b></b><br />
                            <br />
                            <b>8.1.</b> Constituem obrigações e responsabilidades da <b>CONTRATANTE</b>:
                            <br /><b>8.1.1.</b> Validar o acesso ao sistema contratado, de forma a garantir que o sistema se encontra em perfeitas condições de uso e operação.
                            <br /><b>8.1.2.</b> Ser responsável por todas as atividades que venham a ocorrer nas suas contas de usuário do sistema contratado, devendo obedecer a todas as exigências legais aplicáveis ao seu uso do Sistema, incluindo aqueles relativos à transmissão de dados técnicos e pessoais, bem como os da privacidade de dados.
                            <br /><b>8.1.3.</b> Treinar ou contratar serviços de treinamento, caso julgue necessário, aos usuários e/ou novos funcionários que venham a utilizar o sistema contratado.
                            <br /><b>8.1.4.</b> Manter as instalações do computador dentro dos padrões estabelecidos pelos seus fornecedores (Software e Hardware) e compatíveis com o sistema contratado, bem como instalação elétrica adequada, aterramento, estabilizador de tensão elétrica e cabeamento em perfeito estado.
                            <br /><b>8.1.5.</b> Pagamento mensal e pontual do valor da assinatura referente a licença de uso, manutenção e prestação de serviços dentro do prazo de vigência deste Termo de Uso;
                            <br /><b>8.1.6.</b> Cumprir rigorosamente exigências previstas na legislação que rege a emissão do Receituário Agronômico, bem como demais exigências, relacionadas a esse documento, previstas no CREA (Conselhos Regionais de Engenharia e Agronomia), no CFTA (Conselho Federal dos Técnicos Agrícolas) e Defesas Agropecuárias dos Estados brasileiros;
                            <br /><b>8.1.7.</b> Manter os dados cadastrais, como o nome, cargo, telefone e endereço de e-mail, do administrador do Sistema, do responsável pelo faturamento e do(s) usuário(s) do Sistema sempre atualizados em área específica dentro do próprio Sistema. Se as informações fornecidas forem falsas ou fraudulentas, a <b>AGRORECEITA</b> se reserva o direito de encerrar o acesso ao Sistema <b>AGRORECEITA</b> tomando as medidas judiciais cabíveis à situação.
                            <br /><b>8.1.8.</b> Informar por e-mail (suporte@<b>AGRORECEITA</b>.com.br) ou via sistema, em área específica para tal fim, as alterações em Rótulos e Bulas dos Defensivos Agrícolas, Fichas de Emergência e/ou FDS- Fichas com dados de Segurança (antiga FISPQ), tão logo tenha conhecimento; e
                            <br /><b>8.1.9.</b> Enviar à <b>AGRORECEITA</b> todas as informações solicitadas, referente aos produtos do fabricante, importador ou registrante, para o correto cadastramento dos dados de Rótulos e Bulas dos Defensivos Agrícolas, Fichas de Emergência e/ou FDS (antiga FISPQ), especificando suas necessidades, visando uniformidade, padronização e veracidade das informações, de acordo com as normas vigentes.
                            <br /><b>8.1.10.</b> Notificar imediatamente a <b>AGRORECEITA</b> sobre a suspeita de violação de segurança ou sobre o uso não autorizado de senhas e contas;
                            <br /><b>8.1.11.</b> Não se fazer passar por outro usuário e não utilizar informações falsas para obter acesso ao Sistema.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO IX- DAS OBRIGAÇÕES DA <b>AGRORECEITA</b></b><br />
                            <br />
                            <b>9.1.</b> Constituem obrigações da <b>AGRORECEITA</b>
                            <br /><b>9.1.1.</b> Elaborar o layout do Receituário Agronômico condizentes com a legislação que rege a emissão deste documento;
                            <br /><b>9.1.2.</b> Disponibilizar o layout dos Receituários Agronômicos, bem como suas atualizações, desde que solicitadas pelo cliente;
                            <br /><b>9.1.3.</b> Oferecer serviços de suporte por meio de atendimento via WhatsApp (15) 98165-7739,  telefone (15) 3272-9780, e-mail suporte@<b>AGRORECEITA</b>.com.br,  das 8h às 18h, de segunda a sexta feira e aos sábados das 8h às 12h, exceto feriados.
                            <br /><b>9.1.4.</b> Disponibilizar um S.A.C - Serviço de Atendimento ao Cliente, por meio do e-mail suporte@<b>AGRORECEITA</b>.com.br, visando esclarecer dúvidas, orientações técnicas e troca de informações, que serão respondidas e/ou solucionadas conforme Capítulo IV. ;
                            <br /><b>9.1.5.</b> Disponibilizar versões atualizadas do Sistema;
                            <br /><b>9.1.6.</b> Fornecer cópia dos códigos fontes do sistema contratado, somente na hipótese de falência da <b>AGRORECEITA</b>, a fim de garantir a continuidade da utilização do sistema.
                            <br /><b>9.1.7.</b> Prestar os serviços com qualidade e transparência, fornecendo o acesso do Sistema <b>AGRORECEITA</b> no decorrer da vigência deste Termo de Uso.
                            <br /><b>9.1.8.</b> Manter o sigilo das informações pessoais da <b>CONTRATANTE</b>, bem como seus registros de acesso, armazenados em ambiente seguro, em conformidade com as disposições da lei nº 12.965/2014.
                            <br /><b>9.1.9.</b> Os servidores do <b>AGRORECEITA</b> estão hospedados em ambientes seguros, garantindo o acesso a todas as funcionalidades do seu Sistema.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO X- DA SOLICITAÇÃO DE UM NOVO PRODUTO</b><br />
                            <br />
                            <b>10.1.</b> Em caso de inserção/atualização de um novo produto deverá a <b>CONTRATANTE</b>:
                            <br /><b>10.1.1.</b> Solicitar à <b>AGRORECEITA</b> com antecedência mínima de 2 (dois) dias úteis, enviando, através do próprio sistema, todas as informações necessárias para a inserção e/ou atualização;
                            <br /><b>10.1.2.</b> Informar todos os dados originais do fabricante, registrante titular ou importador do produto, bem como todos os dados necessários para o correto preenchimento do documento.
                            <br /><b>10.1.3.</b> Os documentos serão cadastrados no sistema em lotes, de acordo com a quantidade enviada e no prazo estabelecido entre as partes.
                            <br /><b>10.1.4.</b> Após a atualização dos produtos, a <b>AGRORECEITA</b> disponibilizará os dados no sistema, conforme as especificações citadas nesta Cláusula e enviadas pela <b>CONTRATANTE</b>.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO XI- DAS CUSTOMIZAÇÕES</b>
                            <br />
                            <b>11.1. </b>Em caso de necessidade de customização no sistema, a <b>CONTRATANTE</b> deverá enviar a solicitação por escrito à <b>AGRORECEITA</b>, descrevendo a necessidade para posterior avaliação e aprovação.
                            <br /><b>11.2.</b> Fica a exclusivo critério da <b>AGRORECEITA</b> a aprovação da customização solicitada.
                            Caso seja aprovada, de comum acordo entre as partes, serão definidos os valores e prazos para a implementação, mediante a formalização de um ANEXO que deverá compor o presente Termo de Uso.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO XII- DOS DIREITOS SOBRE O SISTEMA - <b>AGRORECEITA</b></b><br />
                            <br />
                            <b>12.1.</b> Fica desde já acordado que é terminantemente proibido ao <b>CONTRATANTE</b>, seus representantes legais, prepostos, empregados, gerentes, procuradores, sucessores ou terceiros interessados:
                            <br /><b>12.1.1. </b>Copiar, alterar, sublicenciar, vender, dar em locação, comodato ou garantia, doar, alienar de qualquer forma, transferir, emprestar ou ceder, total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, o software, nem permitir seu uso por terceiros, a qualquer título, assim como seus manuais ou quaisquer informações relativas ao mesmo;
                            <br /><b>12.1.2.</b>  Revelar, duplicar, copiar ou reproduzir, autorizar ou permitir o uso ou dar conhecimento a terceiros do material didático relacionado a Sistema <b>AGRORECEITA</b>, excetuando-se a necessária capacitação de seus funcionários para uso da Plataforma em relação ao objeto deste instrumento, ficando, neste caso, responsável pela utilização indevida destas informações;
                            <br /><b>12.1.2.</b> Adulterar ou modificar os nomes, marcas, logotipos e demais signos distintivos da <b>AGRORECEITA</b>, existentes nas embalagens, manuais e no Sistema <b>AGRORECEITA</b> e a que se refere o presente Termo de Uso, bem como não poderão ser objeto de venda, licenciamento, locação, comodato, doação, transferência ou transmissão onerosa ou gratuita, salvo prévia e expressa anuência da <b>AGRORECEITA</b>;
                            <br /><b>12.4.</b> A <b>CONTRATANTE</b> comunicará imediatamente à <b>AGRORECEITA</b>, no prazo de 48 (quarenta e oito) horas, sobre a existência de quaisquer ações judiciais, ou procedimentos extrajudiciais que tiver conhecimento, relativos à propriedade intelectual do Sistema <b>AGRORECEITA</b>, nomes, marcas, logotipos e demais signos distintivos, deixando sob o exclusivo controle da <b>AGRORECEITA</b> a defesa que se fizer necessária;
                            <br /><b>12.5.</b> A <b>CONTRATANTE</b> reconhece, para os fins de direito, que os códigos fontes, propriedade intelectual e direitos autorais do Sistema <b>AGRORECEITA</b> pertencem exclusivamente à <b>AGRORECEITA</b>, razão pela qual é vedado à <b>CONTRATANTE</b> promover qualquer tipo de modificação, customização, desenvolvimento, manutenção, suporte, capacitação e consultoria, dentre outros serviços incidentes sobre o Sistema <b>AGRORECEITA</b>, por conta própria ou mediante empresa distinta da <b>AGRORECEITA</b>.
                            <br /><b>12.6.</b> A <b>CONTRATANTE</b> reconhece que a não observância de quaisquer destas obrigações configurará violação da legislação aplicável ao direito autoral e à utilização do Sistema <b>AGRORECEITA</b>, submetendo-se, a própria, bem como seus representantes legais, prepostos, empregados, gerentes, procuradores, sucessores e/ou terceiros interessados, às sanções cíveis e penais cabíveis.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO XIII- DA PROPRIEDADE INTELECTUAL</b><br />
                            <br />
                            <b>13.1.</b> A <b>CONTRATANTE</b> não adquirirá, pelo presente instrumento, nenhum direito de propriedade intelectual ou outros direitos exclusivos da <b>AGRORECEITA</b>, incluindo marcas, direitos autorais ou direitos sobre informações confidenciais ou segredos de negócio, sobre ou relacionados ao Sistema ora licenciado.
                            <br /><b>13.2.</b> Na eventualidade de serem desenvolvidas complementações ao Sistema, provenientes de customizações solicitadas pela <b>CONTRATANTE</b> - nos termos do Capítulo XIII -, declara a <b>CONTRATANTE</b> estar ciente de que tais melhoramentos ou novas funcionalidades daí oriundos serão integralmente incorporados ao Sistema, o qual seguirá sendo de exclusiva titularidade da <b>AGRORECEITA</b>, não existindo, portanto, qualquer forma de coautoria ou copropriedade a elas relacionadas.
                            <br /><b>13.2.1.</b> Considerando a exclusiva titularidade da <b>AGRORECEITA</b> sobre o Sistema acrescido de novas funcionalidades, poderá esta, sem qualquer impedimento, licenciar a terceiros esse conjunto de criações, explorando-o comercialmente sem restrições.
                            <br /><b>13.3.</b> A <b>CONTRATANTE</b> reconhece que o não cumprimento das obrigações ora assumidas resultará em prejuízos irreparáveis para a <b>AGRORECEITA</b>, e que esta poderá adotar qualquer medida que impeça ou restrinja o descumprimento das obrigações ora assumidas, respondendo a PARTE infratora pelos danos, materiais e morais em virtude da violação da propriedade intelectual da <b>AGRORECEITA</b>.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO XIV - DA CONFIDENCIALIDADE</b><br />
                            <br />
                            <b>14.1.</b> A <b>AGRORECEITA</b>, durante a execução do Contrato, utilizará dados e informações da <b>CONTRATANTE</b> para o único propósito de prestar os serviços contratados pela <b>CONTRATANTE</b>. As obrigações estabelecidas neste Contrato aplicam-se a dados e informações (i) confidenciais, (ii) não confidenciais, (iii) protegidos nos termos da Lei Geral de Proteção de Dados ou (iv) não protegidos nos termos da Lei Geral de Proteção de Dados.
                            <br /><b>14.2.</b> As obrigações estabelecidas neste Contrato aplicam-se a dados e informações provenientes da <b>CONTRATANTE</b> ou provenientes de terceiros, que digam respeito à <b>CONTRATANTE</b>.
                            <br /><b>14.3.</b> A <b>AGRORECEITA</b> empenhará seus melhores esforços para assegurar que dados e informações da <b>CONTRATANTE</b> revelados ou disponibilizados para seus empregados, prepostos, funcionários, diretores e quaisquer outros integrantes do seu quadro de pessoal serão mantidas única e exclusivamente entre as pessoas que estarão diretamente envolvidas com a execução do Contrato e que não serão reveladas ou disponibilizadas para pessoas não envolvidas no projeto.
                            <br /><b>14.4.</b> Reconhecendo o caráter confidencial das Informações, as PARTES assumem o compromisso formal de manter estrito e total sigilo com relação à existência e conteúdo das Informações, verbais ou escritas, trocadas entre si e relacionadas ao serviços deste contrato, devendo as mesmas serem tratadas confidencialmente sob qualquer condição, não podendo ser divulgadas a terceiros sem a expressa e escrita autorização da parte que detém a informação, com exceção de informação solicitada por intimação ou citação judicial ou extrajudicial.
                            <br /><b>14.5.</b> As obrigações de confidencialidade vigerão desde a data de assinatura do Termo de Uso até 3 (três) anos após a extinção, independentemente da causa da extinção.
                            <br /><b>14.6.</b> As PARTES reconhecem que o não cumprimento das obrigações ora assumidas resultará em prejuízos irreparáveis para a PARTE prejudicada, e que esta poderá adotar qualquer medida que permita impedir ou restringir o descumprimento das obrigações ora assumidas, respondendo a PARTE infratora pelos danos, materiais e morais em virtude da divulgação ilícita das Informações Confidenciais.
                            <br /><b>14.7.</b> A <b>AGRORECEITA</b> não se responsabiliza pelas informações que a <b>CONTRATANTE</b> enviar para o Sistema durante a vigência deste Contrato, bem como por qualquer correção, exclusão, alteração, perda ou falha no armazenamento de dados que venham a correr a partir do acesso autorizado, através de login e senha cadastrados no Sistema.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO XV- DA MULTA / DA INDENIZAÇÃO</b><br />
                            <br />
                            <b>15.1.</b> Fica acordado entre as partes que, salvo as hipóteses expressas no presente instrumento, na hipótese de infração de qualquer cláusula do presente Termo de Uso, ficará a parte infratora sujeita ao pagamento de multa correspondente a 20% (vinte por cento), calculada sobre a média mensal do valor dos serviços prestados nos últimos 03 (três) meses, além de ressarcir a parte inocente pelas perdas e danos que ocasionar.
                            <br /><b>15.2.</b> Em caso de ação judicial, arcará também a parte infratora, além da multa acima citada, com honorários advocatícios desde já fixados em 20% (vinte por cento) e demais despesas incorridas pela parte inocente.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO XVI - DA AUSÊNCIA DE VÍNCULO TRABALHISTA</b><br />
                            <br />
                            <b>16.1.</b> Fica expressamente estipulado que não está caracterizado, qualquer vínculo empregatício ou de responsabilidade, por parte da <b>AGRORECEITA</b> em relação ao pessoal que a <b>CONTRATANTE</b> empregar direta ou indiretamente, para a utilização e operação do sistema ora contratado.
                            <br /><b>16.2.</b> A <b>CONTRATANTE</b> assume perante a <b>AGRORECEITA</b> a obrigação de excluí-la de imediato de todo e qualquer processo que seja ajuizado por empregado da <b>CONTRATANTE</b>, isentando-a de qualquer ônus ou responsabilidade, considerando a inexistência de qualquer vínculo trabalhista entre a <b>AGRORECEITA</b> e os funcionários da <b>CONTRATANTE</b>.
                            <br /><b>16.3.</b> Em sendo mantida a <b>AGRORECEITA</b> em eventuais reclamações trabalhistas ou ações, administrativas ou judiciais, a <b>CONTRATANTE</b> se obriga, desde logo, e sem qualquer discussão, a ressarcir à <b>AGRORECEITA</b> de todos os valores despendidos e de adiantar pagamentos a serem efetuados em razão de eventuais condenações, no
                            <br /><b>16.4.</b> A <b>AGRORECEITA</b> declara expressamente ser a única responsável por eventuais profissionais/prepostos/colaboradores e/ou empresas por ela contratados, sendo de sua exclusiva responsabilidade o pagamento das respectivas remunerações, despesas trabalhistas e custos oriundos da contratação e/ou demissão destes, inclusive recolhimentos previdenciários, fiscais e tributários relacionados, bem como custos posteriores que venham a ser reclamados por quaisquer de seus empregados ou prestadores de serviços seja na esfera extrajudicial quanto na judicial, ficando desde já certo e acordado que a <b>CONTRATANTE</b> não será solidária ou subsidiariamente responsável pelas obrigações assumidas pela <b>AGRORECEITA</b> na prestação dos Serviços.
                            <br /><b>16.5.</b> Caso a <b>CONTRATANTE</b> venha a ser incluída no polo passivo de demandas judiciais promovidas por prepostos, funcionários, colaboradores e fornecedores da <b>AGRORECEITA</b>, em virtude da prestação de serviços objeto deste Termo de uso, os custos com a defesa da <b>CONTRATANTE</b> no respectivo processo, bem como todos os custos com eventuais indenizações, acordos, perícias e demais gastos, serão de responsabilidade exclusiva da <b>AGRORECEITA</b>, não cabendo à <b>CONTRATANTE</b> qualquer ônus para a apresentação de sua defesa bem como de acompanhamento do processo até sua decisão. Tal condição permanecerá vigente mesmo após a rescisão ou término do presente Termo, em qualquer tempo e circunstância.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO XVII - DAS DECLARAÇÕES DA <b>CONTRATANTE</b></b><br />
                            <br />
                            <b>17.1.</b> A <b>CONTRATANTE</b> declara ser a pessoa autorizada para realizar a contratação do Sistema <b>AGRORECEITA</b>, tendo lido, compreendido e aceito todas as condições deste Termo de Uso, tendo total entendimento dos direitos e obrigações decorrentes do mesmo.
                            <br /><b>17.2.</b> A <b>CONTRATANTE</b> declara, também, que está ciente de que todas as operações que realizar no Sistema <b>AGRORECEITA</b> serão registradas no banco de dados da <b>AGRORECEITA</b>, podendo tais informações ser utilizadas como prova pelas partes, independente do cumprimento de qualquer outra formalidade.
                            <br /><b>17.3.</b> A <b>CONTRATANTE</b> reconhece que a <b>AGRORECEITA</b>, em nenhuma circunstância, será responsável por quaisquer tipos de danos (indiretos, punitivos, incidentais, consequenciais e/ou outros) relacionados ao uso  do Sistema <b>AGRORECEITA</b>, incluindo perda de dados, receita, lucro cessante, uso ou outra vantagem econômica relacionados a esta prestação de serviço, como o uso ou incapacidade de usar o serviço, ou qualquer conteúdo obtido do serviço ou por intermédio dele, sendo este interrupção, imprecisão, erro ou omissão, independentemente de causa no conteúdo, mesmo que a parte da qual se tente reaver os danos, ou de seus licenciadores, tenham sido avisados previamente sobre a possibilidade de tais danos.
                            <br /><br /><b style={{ fontWeight: '700' }}>CAPÍTULO XVIII- DA ISENÇÃO DE RESPONSABILIDADE</b><br />
                            <br />
                            <b>18.1.</b> A <b>AGRORECEITA</b> fica isenta de qualquer responsabilidade, indenização, perdas e danos, lucros cessantes ou prejuízos de qualquer natureza causados a <b>CONTRATANTE</b> e/ou terceiros, em virtude de interrupção do funcionamento do Sistema <b>AGRORECEITA</b>, em razão de defeito na instalação física (computador-hardware), alteração na configuração do hardware, sistema operacional, softwares concorrentes, inadequação da rede local, manipulação indevida do Sistema por parte dos usuários e/ou terceiros que tiverem acesso ao sistema, infestação de vírus do equipamento onde o sistema encontra-se em uso ou a ele tenha acesso, bem como qualquer outro evento gerado exclusivamente pela <b>CONTRATANTE</b>.
                            <br /><b>18.2.</b> Fica a <b>AGRORECEITA</b> isenta de qualquer responsabilidade sobre as informações contidas nos Receituários Agronômicos tendo em vista que é de responsabilidade da <b>CONTRATANTE</b> a prescrição correta dos defensivos agrícolas, bem como o envio do conteúdo dos produtos, sempre que encontrar qualquer nova informação ou necessidade de alteração, bem como o envio dos dados técnicos recebidos dos fabricantes, registrantes e/ou importador de produtos cadastrados ou não na Plataforma <b>AGRORECEITA</b>.
                            <br /><b>18.3.</b> A <b>AGRORECEITA</b> não se responsabiliza por atrasos ou outros problemas decorrentes do uso da internet ou de comunicações eletrônicas, que podem gerar falhas de entrega ou quaisquer outros danos.
                            <br /><b>18.4.</b>  A <b>AGRORECEITA</b> não se responsabiliza pelo cumprimento dos prazos legais da <b>CONTRATANTE</b> para envio das informações referentes aos Receituários Agronômicos às Defesas Agropecuárias de seus estados.
                            <br /><b>18.5.</b> A <b>AGRORECEITA</b> não se responsabiliza por invasões de hackers e/ou quaisquer outros ataques que venham a ocorrer no sistema, servidor ou Sistema, não sendo a responsável por qualquer exclusão, utilização ou divulgação não autorizada das informações. Contudo, para a proteção das informações da <b>CONTRATANTE</b>, armazenadas no Sistema <b>AGRORECEITA</b>, adota as medidas de segurança adequadas de acordo com os padrões de mercado. A <b>CONTRATANTE</b>, também, reconhece que nenhum sistema, servidor ou Sistema estão imunes a invasões e/ou ataques de hackers.
                            <br /><b>18.6.</b> A <b>AGRORECEITA</b> não se responsabiliza pelas informações que a <b>CONTRATANTE</b> enviar para o Sistema <b>AGRORECEITA</b> durante a vigência deste Termo de Uso, bem como por qualquer correção, exclusão, alteração, perda ou falha no armazenamento de dados que venham a correr a partir do acesso autorizado, através de login e senha cadastrados na base do Sistema <b>AGRORECEITA</b>.
                            <br /><br /><b style={{ fontWeight: '700' }}>CLÁUSULA XIV- DAS CONDIÇÕES GERAIS</b><br />
                            <br />
                            <b>19.1.</b> Os direitos da <b>CONTRATANTE</b> estão em conformidade com a Lei de Informática nº 7232/84, Decreto nº 93.295 de 25/09/86, Resolução CONIN 026/86, Lei de Software nº 7646/87 e Decreto nº 96.036 de 12/05/88.
                            <br /><b>19.2.</b> A <b>CONTRATANTE</b> autoriza a <b>AGRORECEITA</b> a divulgar e citar seu nome, inclusive com publicação do logotipo, sem pagamento de qualquer tipo de remuneração, em seus materiais promocionais, através de órgãos de comunicação, em sites, homepages, releases, presentations e quaisquer outros meios publicitários disponíveis, bem como expressar o nome da <b>CONTRATANTE</b> na lista de clientes do sistema.
                            <br /><b>19.3.</b> Em cumprimento a Lei Geral de Proteção de Dados, Lei 13.709/2018, a <b>CONTRATANTE</b> autoriza a <b>AGRORECEITA</b> a enviar emails marketing, com conteúdos relacionados a legislação de produtos químicos e materiais afins. A <b>AGRORECEITA</b> respeita a privacidade de seus clientes, parceiros e fornecedores, não fazendo spam.
                            <br /><b>19.4.</b> Qualquer concessão ou tolerância das partes acerca do descumprimento de qualquer cláusula deste Termo de Uso deverá ser vista como mera liberalidade, não podendo ser invocado pela parte contrária como motivo para novação ou modificação do acordado.
                            <br /><b>19.5.</b> Para todos os efeitos, as partes declaram que não existe qualquer vinculação entre as empresas <b>CONTRATANTE</b>s de ordem societária e administrativa, não se estabelecendo qualquer forma de sociedade, associação, parceria, consórcio ou responsabilidade solidária.
                            <br /><b>19.6.</b> Os direitos e obrigações assumidos neste instrumento não poderão ser objeto de cessão ou transferência, sem o prévio e expresso consentimento da outra parte.
                            <br /><b>19.7.</b> O presente Termo de Uso obriga as partes e seus sucessores, a qualquer título, respeitando as cláusulas que integram este instrumento que poderão ser alteradas somente mediante a celebração de um termo aditivo.
                            <br /><b>19.8.</b> Os casos omissos serão resolvidos de comum acordo, devendo ser elaborado um termo aditivo a este instrumento.
                            <br /><br /><b style={{ fontWeight: '700' }}>CLÁUSULA XX- DO FORO E DA FORMALIDADE DE ASSINATURA</b><br />
                            <br />
                            <b>20.1.</b>Fica eleito o Foro da cidade de Itapetininga, Estado de São Paulo, para dirimir quaisquer questões oriundas do presente Termo de Uso, com renúncia a qualquer outro, por mais privilegiado que seja.
                            <br /><b>20.2.</b>  Nos termos do art. 10, § 2º, da Medida Provisória nº 2.200-2, as PARTES expressamente concordam em utilizar e reconhecem como válida qualquer forma de comprovação de anuência aos termos ora acordados em formato eletrônico, ainda que não utilizem de certificado digital emitido no padrão ICP-Brasil, incluindo assinaturas eletrônicas. A formalização das avenças na maneira supra acordada será suficiente para a validade e integral vinculação das PARTES ao presente Contrato, para o fim de produzir plenos e integrais efeitos jurídicos, vinculando-os reciprocamente, inclusive para fins de propositura de ação de execução extrajudicial.
                            <br />
                            <br />
                        </p>
                        <p style={{ textAlign: 'center', fontWeight: '300' }}>
                            <b style={{ fontWeight: '700' }}><b>AGRORECEITA</b> TECNOLOGIA AGRICOLA E AMBIENTAL LTDA</b>
                            <br />Última atualização 01/04/2025<br />
                            <br />ASSINADO ELETRONICAMENTE
                        </p>
                    </div>
                </ModalBody>

                <ModalFooter>
                    {this.props.btnOk == true ? (
                        <Button
                            style={{ outline: 0, padding: "10px", width: "100px", margin: "auto", marginTop: '15px' }}
                            color="#00b43f"
                            onClick={() => this.updateOk()}
                        >
                            ENTENDI
                        </Button>
                    ) : (null)}
                </ModalFooter>
            </Modal>

        );
    }
}
export default ModalNew;
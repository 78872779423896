import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import api from "../../services/api";
import { store } from "react-notifications-component";
import GenericUpper from "../../components/generic-upper";
import PrescriptionStepOne from "../../components/prescriptionsteps/step1";
import PrescriptionStepTwo from "../../components/prescriptionsteps/step2";
import PrescriptionStepThree from "../../components/prescriptionsteps/step3";
import Button from '../../components/button/button_v2';
import ModalYesNo from "../../components/modal/modalYesNo";
import ModalPassword from "../../components/modal/modal_password";
import ModalUpload from '../../components/modal/modalUploadCertificado';
import ModalAlert from '../../components/modal/modalGeneric';
import { formatDate } from "../../services/formatDate";
import { getIp } from "../../services/getIp";
import PrescriptionStepFour from "../../components/prescriptionsteps/step4";
import { DivSaldo, DivSaldoContainer } from '../receitas/styles';
import PrescriptionStepFive from "../../components/prescriptionsteps/step5";


const AddReceitasLote = ({ ...props }) => {

    const [title] = useState("Descobrindo o problema");
    const [subtitle, setSubtitle] = useState("");
    const [maxRelations, setMaxRelations] = useState("");
    const [align] = useState("center");
    const [userIp, setUserIp] = useState('');
    const [loadParams, setLoadParams] = useState(false);


    //step1
    const [stepOneParams, setStepOneParams] = useState([]);
    const [dataEmissao, setDataEmissao] = useState('');
    const [countRelations, setCountRelations] = useState(0);
    const [erroStepOne, setErroStepOne] = useState(false);
    

    //step2
    const [stepTwoParams, setStepTwoParams] = useState([]);
    const [existParamsStepTwo, setExistParamsStepTwo] = useState(false);
    const [stepTwoError, setStepTwoError] = useState(false);
    const [isOpenStepTwo, setIsOpenStepTwo] = useState(false);
    const [errosNfGo, setErrosNfGo] = useState([]);

    //step3
    const [stepThreeParams, setStepThreeParams] = useState([]);
    const [existParamsStepThree, setExistParamsStepThree] = useState(false);
    const [disabledStepThree, setDisabledStepThree] = useState(true);
    const [stepThreeError, setStepThreeError] = useState(false);
    const [isOpenStepThree, setIsOpenStepThree] = useState(false);

    const [senha, setSenha] = useState('');

    const [showModalYesNo, setShowModalYesNo] = useState(false);
    const [showModalPassword, setShowModalPassword] = useState(false);
    const [showModalUpload, setShowModalUpload] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);

    const [disabledFinish, setDisabledFinish] = useState(true);

    //step5
    const [relationsSelecionadas, setRelationsSelecionadas] = useState([]);
    const [siccaInfo, setSiccaInfo] = useState('');

    //compendio
    const [existCompendioParams, setExistCompendioParams] = useState(false);
    const [compendioParams, setCompendioParams] = useState([]);
    const [loadingPrescription, setLoadingPrescription] = useState(false);
    const [existPassword, setExistPassword] = useState(false);
    const [sendPrescription, setSendPrescription] = useState(false);
    const [msgError, setMsgError] = useState('');

    const [iniciaVerificacao, setIniciaVerificacao] = useState(false);
    const [existEmptyValues, setExistEmptyValues] = useState(true);
    const [isApto, setIsApto] = useState(true);
    const [planoConfig, setPlanoConfig] = useState([]);
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [saldoReceitas, setSaldoReceitas] = useState('')
    const [produtosSelecionados, setProdutosSelecionados] = useState([]);
    const [NFeInfo, setNFeInfo] = useState('');

    const [exitDateValidation, setExitDateValidation] = useState(false);
    const [showExitValidation, setShowExitValidation] = useState(false);

    const validaSaldo = async () => {
        try {
            const response = await api.get('/receita/saldo');

            if (response) {
                setIsApto(true);
                setSaldoReceitas(response.data.data.saldo)

            }

        } catch (error) {
            if (error.response.status === 423) { 
                setIsApto(false);
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: "Entre em contato com nosso suporte!",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                });
            }
        }
    }   

    const goHome = () => {
        setIsApto(!isApto);
        props.history.push('/');
    }
    
    const goToPlans = () => {
        setIsApto(!isApto);
        props.history.push('/planos');
    }
    
    const goToReceitas = () => {
        setIsApto(!isApto);
        props.history.push('/comprar_receitas');
    }
    
    const goToLimit = () => {
        setIsApto(!isApto);
        props.history.push('/comprar_limite');
    }
    
    const saveIp = async () => {
        await getIp( function (ipUser) {
            setUserIp(ipUser);
        });
    }

    const updateParamsOne = (value) => {

        setStepOneParams(value);
        if (value.relations.length > 0) {
            if (value.relations[0]) {
                if (value.relations[0].date) {
                    setDataEmissao(value.relations[0].date);
                }
            }
        }

        if (value.estado) {
            if (value.estado.value === '' && value.estado.label === '') {
                setMsgError('Selecione um estado!');
                setStepTwoParams([]);
                setStepThreeParams([]);
                setExistParamsStepTwo(false);
                setExistParamsStepThree(false);
                setDisabledStepThree(true);
            } else {
                if (value.estado.value === '') {
                    setMsgError('Selecione um estado!');
                    setDisabledStepThree(true);

                } else {
                    if (value.estado.value !== 'RJ') {
                        if (stepTwoParams != []) {
                            if (stepTwoParams.art && stepTwoParams.art != undefined) {
                                setDisabledStepThree(false);
                            } else {
                                setDisabledStepThree(true);
                            }
                        } else {
                            setDisabledStepThree(true);
                        }
                    } else {
                        if (stepTwoParams.length !== 0) {
                            setDisabledStepThree(false);
                        }
    
                    }
                }
            }
        } else {
            setDisabledStepThree(true);
        }
        setRelationsSelecionadas(value.relations);
        setProdutosSelecionados(value.produtos);
    }

    const resetStepThree = () => {
        setStepThreeParams([]);
        setExistParamsStepThree(false);
        setStepThreeError(false);
        setIsOpenStepThree(false);
    }

    const updateParamsTwo = (value) => {

        setStepTwoParams(value);

        if (stepTwoParams.length > 0) {
            if (stepTwoParams.art !== '' && stepTwoParams.crea !== '' && value.art !== '' && value.crea !== '') {
                if (stepTwoParams.art.value !== value.art.value || stepTwoParams.crea.value !== value.crea.value) {
                    resetStepThree()
                }
            }
        }

        if (value.password) {
            setSenha(value.password);
        }


        if (value.responsavel.value || value.crea.value || value.assinatura.value || value.art.value) {
            if (value.erroUser) {
                setDisabledStepThree(true);
                setExistParamsStepTwo(true);
            } else {
                setMsgError('');
                if (stepOneParams.estado.value === 'RJ') {
                    if(value.responsavel.value && value.crea.value && value.assinatura.value) {
                        setDisabledStepThree(false);
                    }
                } else if(value.responsavel.value && value.crea.value && value.assinatura.value && value.art.value) {
                    setDisabledStepThree(false);
                }

                setExistParamsStepTwo(true);
            }
        } else {
            if (stepOneParams.estado) {
                if (stepOneParams.estado.value === 'SP' || stepOneParams.estado.value === 'MG') {
                    if (value.art) {
                        // setDisabledStepThree(false);
                        setExistParamsStepTwo(false);
                    } else {
                        setDisabledStepThree(true);
                        setExistParamsStepTwo(false);
                    }
                } else {
                    setDisabledStepThree(true);
                    setExistParamsStepTwo(false);
                }
            }
        }

        if (value.erroResponsavel === true || value.erroCrea === true || value.erroAssinatura === true || value.erroArt === true) {
            setStepTwoError(true);
        } else {
            setMsgError('');
            setStepTwoError(false);
        }
    }

    const updateIsOpenStepTwo = (isOpen) => {

        if (isOpen === true) {
            if(isOpenStepThree === true) {
                setIsOpenStepThree(false);
            } 
        }
        setIsOpenStepTwo(isOpen);
    }

    const updateParamsThree = (value) => {
        
        if (value.produtor || value.fazenda || value.devolucao) {
            setMsgError('');
            setExistParamsStepThree(true);
            setStepThreeParams(value);
        }

        if (value.erroProdutor === true || value.erroFazenda === true || value.erroDevolucao === true) {
            setStepThreeError(true);
        } else {
            setMsgError('');
            setStepThreeError(false);
        }


    }

    const updateIsOpenStepThree = (isOpen) => {
        if (isOpen === true) {
            if(isOpenStepTwo === true) {
                setIsOpenStepTwo(false);
            } 
        }
        setIsOpenStepThree(isOpen);
    }

    const updateQuantidadeRelations = (quantidade) => {
        setMaxRelations(quantidade);
    }

    const validatePrescription = (params) => {
        if (params) {

            if (params.stepOneParams) {
                
                if (params.stepOneParams.estado && params.stepOneParams.estado !== '') {
                    if (params.stepOneParams.estado.value) {
                        if (params.stepOneParams.cultura != '') {
                            if (params.stepOneParams.relations != []) {
                                if (params.stepOneParams.relations.length > 0) {

                                    if (params.stepOneParams.relations[0]) {
                                        if (erroStepOne === false) {
                                            if (
                                                params.stepOneParams.relations[0].areaTratar !== '' &&
                                                params.stepOneParams.relations[0].dose !== '' &&
                                                params.stepOneParams.relations[0].modalidadeAplicacao &&
                                                params.stepOneParams.relations[0].numeroAplicacoes !== '' &&
                                                params.stepOneParams.relations[0].quantidadeAdquirir !== '' &&
                                                params.stepOneParams.relations[0].unidadeQuantidadeAdquirir !== '' &&
                                                params.stepOneParams.relations[0].volumeCalda !== ''
                                            ){
                                                if (params.dataEmissao) {
                                                    setMsgError('');
                                                    if (params.existParamsStepTwo === true && params.existParamsStepThree === true) {
                                                        if (params.stepTwoError === false && params.stepThreeError === false) {
                                                            if (params.stepTwoParams) {
                                                                if (params.stepTwoParams.erroResponsavel === false && params.stepTwoParams.erroCrea === false && params.stepTwoParams.erroArt === false) {
                                                                    if (params.stepTwoParams.responsavel.value) {
                                                                        if (params.stepTwoParams.crea.value) {
                                                                            if (params.stepTwoParams.assinatura.value) {
                                                                                if (params.stepOneParams.estado.value != 'RJ') {
                                                                                    if (params.stepTwoParams.art.value) {
                                                                                        //valida step3
                                                                                        if (params.stepThreeParams) {
                                                                                            if (params.stepThreeParams.erroProdutor === false && params.stepThreeParams.erroFazenda === false && params.stepThreeParams.erroDevolucao === false) {
                                                                                                if (params.stepThreeParams.produtor.value) {
                                                                                                    if (params.stepThreeParams.fazenda.value) {
                                                                                                        if (params.stepOneParams.estado.value === 'SP') {
                                                                                                            setDisabledFinish(false);
                                                                                                        } else  if (params.stepThreeParams.devolucao.value) {
                                                                                                            if (existEmptyValues) {
                                                                                                                setDisabledFinish(true);
                                                                                                                setMsgError('Existem relações incompletas!');
                                                                                                            } else {
                                                                                                                setDisabledFinish(false);
                                                                                                            }
                                                                                                        } else {
                                                                                                            setDisabledFinish(true);
                                                                                                        }                                                                    
                                                                                                    } else {
                                                                                                        setDisabledFinish(true);
                                                                                                    }
                                                                                                } else {
                                                                                                    setDisabledFinish(true);
                                                                                                }
                                                                                            } else {
                                                                                                setMsgError('Verifique as informações de onde o produto será aplicado!');
    
                                                                                                setDisabledFinish(true);
                                                                                            }
                                                                                        } else {
                                                                                            setDisabledFinish(true);
                                                                                        }
                                                                                    } else {
                                                                                        setDisabledFinish(true);
                                                                                    }
                                                                                } else {
                                                                                    //valida step3
                                                                                    if (params.stepThreeParams) {
                                                                                        if (params.stepThreeParams.erroProdutor === false && params.stepThreeParams.erroFazenda === false && params.stepThreeParams.erroDevolucao === false) {
                                                                                            if (params.stepThreeParams.produtor.value) {
                                                                                                if (params.stepThreeParams.fazenda.value) {
                                                                                                    if (params.stepThreeParams.devolucao.value) {
                                                                                                        if (existEmptyValues) {
                                                                                                            setDisabledFinish(true);
                                                                                                            setMsgError('Existem relações incompletas!');
                                                                                                        } else {
                                                                                                            setDisabledFinish(false);
                                                                                                        }
                                                                                                    } else {
                                                                                                        setDisabledFinish(true);
                                                                                                    }
                                                                                                } else {
                                                                                                    setDisabledFinish(true);
                                                                                                }
                                                                                            } else {
                                                                                                setDisabledFinish(true);
                                                                                            }
                                                                                        } else {
                                                                                            setDisabledFinish(true);
                                                                                        }
                                                                                    } else {
                                                                                        setDisabledFinish(true);
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                setDisabledFinish(true);
                                                                            }
                                                                        } else {
                                                                            setDisabledFinish(true);
                                                                        }
                                                                    } else {
                                                                        setDisabledFinish(true);
                                                                    }
                                                                } else {
                                                                    setMsgError('Verifique as informações do responsável técnico');
    
                                                                    setDisabledFinish(true);
                                                                }
                                                            } else {
                                                                setDisabledFinish(true);
                                                            }
                                                        } else {
                                                            if (params.stepTwoError) {
                                                                setMsgError('Verifique as informações do responsável técnico');
    
                                                            }
    
                                                            if ( params.stepThreeError) {
                                                                setMsgError('Verifique as informações de onde o produto será aplicado!');
    
                                                            }
                                                            setDisabledFinish(true);
                                                        }
                                                    } else {
                                                        if (params.stepTwoError) {
                                                            setMsgError('Verifique as informações do responsável técnico');
    
                                                        }
    
                                                        if ( params.stepThreeError) {
                                                            setMsgError('Verifique as informações de onde o produto será aplicado!');
    
                                                        }
                                                        setDisabledFinish(true);
                                                    }
                                                } else {
                                                    setDisabledFinish(true);
                                                }
                                            } else {
                                                setMsgError('Verifique a prescrição do(s) produto(s)!');
                                                setDisabledFinish(true);
                                            }
                                        } else {
                                            setMsgError('Existem erros nas relações!');
                                            setDisabledFinish(true);
                                        }
                                    }
                                } else {
                                    setDisabledFinish(true);
                                }
                            } else {
                                setDisabledFinish(true);
                            }
                        } else {
                            if (params.stepOneParams.length > 0) {
                                if (params.stepOneParams.relations.length > 0) {
                                    setMsgError('Selecione uma cultura!');
                                }
                            }  else if (params.stepOneParams.length === undefined) {
                                setMsgError('Selecione uma cultura!');
                            }
                            setDisabledFinish(true);
                        }
                    } else {
                        if (params.stepOneParams.length > 0) {
                            if (params.stepOneParams.relations.length > 0) {
                                setMsgError('Selecione um estado!');
                            }
                        } else if (params.stepOneParams.length === undefined) {
                            setMsgError('Selecione um estado!');
                        }
                        setDisabledFinish(true);
                    }
                } else {
                    
                    if (params.stepOneParams.length > 0) {
                        if (params.stepOneParams.relations.length > 0) {
                            setMsgError('Selecione um estado!');
                        }
                    } else if (params.stepOneParams.length === undefined) {
                        setMsgError('Selecione um estado!');
                    }
                    setDisabledFinish(true);
                }
            } else {
                setDisabledFinish(true);
            }
        } else {
            setDisabledFinish(true);
        }
    }

    //formats
    const formatRelationsIds = (relations) => {
        let format = [];

        relations.map((rel) => {
            format = [...format, rel.relationId];
        });

        return format;
    }
    
    const formatAplicationModesIds = (relations) => {
        let format = [];

        relations.map((rel) => {
            if (rel.modalidadeAplicacao.value) {
                format = [...format, rel.modalidadeAplicacao.value];
            }
        });

        return format;
    }
    
    const formatDoses = (relations) => {
        let format = [];

        relations.map((rel) => {
            format = [...format, rel.dose];
        });

        return format;
    }
    
    const formatNumeroAplicacoes = (relations) => {
        let format = [];

        relations.map((rel) => {
            if (rel.numeroAplicacoes) {
                format = [...format, rel.numeroAplicacoes];
            }
        });

        return format;
    }
    
    const formatQuantidadeAdquirir = (relations) => {
        let format = [];

        relations.map((rel) => {
            if (rel.quantidadeAdquirir) {
                format = [...format, rel.quantidadeAdquirir];
            }
        });

        return format;
    }
    
    const formatUnidade = (relations) => {
        let format = [];

        relations.map((rel) => {
            if (rel.unidadeQuantidadeAdquirir) {
                format = [...format, rel.unidadeQuantidadeAdquirir];
            }
        });

        return format;
    }
    
    const formatArea = (relations) => {
        let format = [];

        relations.map((rel) => {
            if (rel.areaTratar) {
                format = [...format, rel.areaTratar];
            }
        });

        return format;
    }
    
    const formatEquipamento = (relations) => {
        let format = [];

        relations.map((rel) => {
            format = [...format, rel.equipamento];
        });

        return format;
    }

    const formatVolume = (relations) => {
        let format = [];

        relations.map((rel) => {
            format = [...format, rel.volumeCalda];
        });

        return format;
    }

    const validaRelations = () => {
        setIniciaVerificacao(true);
    }

    const updateYesNo = async (resposta) => {
        if (resposta === 1) {
            setShowModalYesNo(false);
            validaRelations();
        }else{
            setShowModalYesNo(false);
        }
    }

    const updatePassword = (senha) => {
        setSenha(senha);
        setShowModalPassword(false);
        setExistPassword(true);
    }

    const enviarReceita = () => {
        setSendPrescription(true);
    }

    const updateYesNoDateValidate = (e) => {
        if (e === 1) {
            setExitDateValidation(true);
            setShowExitValidation(false);

            setTimeout(() => {
                enviarReceita();
            }, 300);
        } else {
            setShowExitValidation(false);

            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 200);
        }
    }

    const validaNFe = (nf) => {
        const erros = [];

        if(!nf.chaveAcesso){
            erros.push('chaveAcesso')
        }
        
        if(!nf.razaoSocial){
            erros.push('razaoSocial')
        }
        
        if(!nf.cpfCnpj){
            erros.push('cpfCnpj')
        }
        
        if(!nf.endereco){
            erros.push('endereco')
        }

        if(!nf.bairro){
            erros.push('bairro')
        }

        if(!nf.municipio){
            erros.push('municipio')
        }

        if(!nf.estado.value){
            erros.push('estado')
        }

        if(!nf.dataEmissao){
            erros.push('dataEmissao')
        }

        if(nf.produtos[0] == ''){
            erros.push('produtos')
        }

        if(!nf.ncmProdutos.length > 0){
            erros.push('ncmProdutos')
        }


        if(!nf.unidades.length > 0){
            erros.push('unidades')
        }

        if(!nf.quantidades.length > 0){
            erros.push('quantidades')
        }

        setErrosNfGo(erros);

        if (
            nf.chaveAcesso &&
            nf.razaoSocial &&
            nf.cpfCnpj &&
            nf.endereco &&
            nf.bairro &&
            nf.municipio &&
            nf.estado.value &&
            nf.dataEmissao &&
            nf.codigosProduto &&
            nf.produtos &&
            nf.ncmProdutos &&
            nf.unidades &&
            nf.quantidades
        ) {
            return true;
        } else {
            return false;
        }
    }

    const emitirReceita = async (values) => {
        try {
            if (values.stepTwoParams.assinatura.value === 'D' && senha === '') {
                setShowModalPassword(true);
            } else {
                setLoadingPrescription(true);
                const agro_relation_id = formatRelationsIds(values.stepOneParams.relations);
                const agro_aplication_mode_id = formatAplicationModesIds(values.stepOneParams.relations);
                const dose = formatDoses(values.stepOneParams.relations);
                const numero_aplicacoes = formatNumeroAplicacoes(values.stepOneParams.relations);
                const quantidade_adquirir = formatQuantidadeAdquirir(values.stepOneParams.relations);
                const unidade_quantidade_adquirir = formatUnidade(values.stepOneParams.relations);
                const area_tratar = formatArea(values.stepOneParams.relations);
                const equipamento_aplicacao = formatEquipamento(values.stepOneParams.relations);
                const volume_calda = formatVolume(values.stepOneParams.relations);
    

                const contRelations = agro_relation_id.length;

                if (
                    area_tratar.length === contRelations &&
                    dose.length === contRelations &&
                    equipamento_aplicacao.length === contRelations &&
                    numero_aplicacoes.length === contRelations &&
                    quantidade_adquirir.length === contRelations &&
                    unidade_quantidade_adquirir.length === contRelations &&
                    contRelations == countRelations
                ) {

                    let payload = {};

                    let emitir = false;
                    
                    if (stepOneParams.estado.value === 'GO') {
                        emitir = validaNFe(NFeInfo);

                        if (emitir) {
                            payload = {
                                agro_relation_id,
                                agro_aplication_mode_id,
                                dose,
                                volume_calda,
                                numero_aplicacoes,
                                quantidade_adquirir,
                                unidade_quantidade_adquirir,
                                area_tratar,
                                equipamento_aplicacao,
                                agro_crea_id: values.stepTwoParams.crea.value,
                                agro_art_id: values.stepTwoParams.art.value,
                                agro_farm_id: values.stepThreeParams.fazenda.value,
                                agro_devolution_id: values.stepThreeParams.devolucao.value,
                                assinatura: values.stepTwoParams.assinatura.value,
                                salvarAssinatura: values.stepTwoParams.salvarAssinatura,
                                senha,
                                data_emissao: dataEmissao,
                                ip: userIp,
                                lat: lat,
                                lng: lng,
                                chave_acesso: NFeInfo.chaveAcesso,
                                razao_social_emissor: NFeInfo.razaoSocial,
                                cpf_cnpj_emissor: NFeInfo.cpfCnpj,
                                endereco_emissor: NFeInfo.endereco,
                                bairro_emissor: NFeInfo.bairro,
                                municipio_emissor: NFeInfo.municipio,
                                uf_emissor: NFeInfo.estado.value,
                                data_emissao_nf: NFeInfo.dataEmissao,
                                nome_produto: NFeInfo.produtos,
                                ncm_produto: NFeInfo.ncmProdutos,
                                unidade_medida: NFeInfo.unidades,
                                qtd_produto: NFeInfo.quantidades,
                                num_nf: NFeInfo.numNf,
                                exit_date_validation: exitDateValidation,
                                sicca: siccaInfo
                            }
                        } else {    
                            store.addNotification({
                                title: "Erro!",
                                message: `Todos os campos da nota fiscal são obrigatórios!`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 6000,
                                    onScreen: true
                                }
                            });
                        }

                    } else if (stepOneParams.estado.value === 'SP'){
                        emitir = true;
                        payload = {
                            agro_relation_id,
                            agro_aplication_mode_id,
                            dose,
                            volume_calda,
                            numero_aplicacoes,
                            quantidade_adquirir,
                            unidade_quantidade_adquirir,
                            area_tratar,
                            equipamento_aplicacao,
                            agro_crea_id: values.stepTwoParams.crea.value,
                            agro_art_id: values.stepTwoParams.art.value,
                            agro_farm_id: values.stepThreeParams.fazenda.value,
                            agro_devolution_id: values.stepThreeParams.devolucao.value || "",
                            assinatura: values.stepTwoParams.assinatura.value,
                            salvarAssinatura: values.stepTwoParams.salvarAssinatura,
                            senha,
                            data_emissao: dataEmissao,
                            // hora_emissao: hour,
                            ip: userIp,
                            lat: lat,
                            lng: lng,
                            num_nf: NFeInfo.numNf,
                            exit_date_validation: exitDateValidation,
                            sicca: siccaInfo,
                            // complementar_mt: firstMtInfo.firstPrescription,
                            // numero_primeira_art: firstMtInfo.numberFirstArt
                        }
                    } else {
                        emitir = true;
                        payload = {
                            agro_relation_id,
                            agro_aplication_mode_id,
                            dose,
                            volume_calda,
                            numero_aplicacoes,
                            quantidade_adquirir,
                            unidade_quantidade_adquirir,
                            area_tratar,
                            equipamento_aplicacao,
                            agro_crea_id: values.stepTwoParams.crea.value,
                            agro_art_id: values.stepTwoParams.art.value,
                            agro_farm_id: values.stepThreeParams.fazenda.value,
                            agro_devolution_id: values.stepThreeParams.devolucao.value,
                            assinatura: values.stepTwoParams.assinatura.value,
                            salvarAssinatura: values.stepTwoParams.salvarAssinatura,
                            senha,
                            data_emissao: dataEmissao,
                            ip: userIp,
                            lat: lat,
                            lng: lng,
                            num_nf: NFeInfo.numNf,
                            exit_date_validation: exitDateValidation,
                            sicca: siccaInfo
                        }
                    }
        
                    if (emitir) {
                        const response = await api.post(`/receitas/lote`, payload);
                        // const receitaId = response.data.data.id;
                        
                        if (response) {
        
                            if (response.status === 206) {
                                store.addNotification({
                                    title: "Sucesso!",
                                    message: `Receita emitida com sucesso!`,
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                                if(response.data.message === "A receita precisa ser assinada pelo responsável técnico antes de ser enviada!"){
                                    store.addNotification({
                                        title: "Erro!",
                                        message: `${response.data.message}`,
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animated", "fadeIn"],
                                        animationOut: ["animated", "fadeOut"],
                                        dismiss: {
                                            duration: 10000,
                                            onScreen: true
                                        }
                                    });
                                }else{
                                    store.addNotification({
                                        title: "Erro!",
                                        message: `${response.data.message}  Aguarde alguns minutos ou entre em contato com nosso suporte!`,
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animated", "fadeIn"],
                                        animationOut: ["animated", "fadeOut"],
                                        dismiss: {
                                            duration: 10000,
                                            onScreen: true
                                        }
                                    });
                                }
                            } if (response.status === 426) {
                                setShowExitValidation(true);
                                setSendPrescription(false);
                            } else {
                                if (response.status === 200 && response.data.message) {
                                    store.addNotification({
                                        title: "Sucesso!",
                                        message: `${response.data.message}`,
                                        type: "success",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animated", "fadeIn"],
                                        animationOut: ["animated", "fadeOut"],
                                        dismiss: {
                                            duration: 4000,
                                            onScreen: true
                                        }
                                    });
                                } else {
                                    store.addNotification({
                                        title: "Sucesso!",
                                        message: "Receita emitida com sucesso!",
                                        type: "success",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animated", "fadeIn"],
                                        animationOut: ["animated", "fadeOut"],
                                        dismiss: {
                                            duration: 4000,
                                            onScreen: true
                                        }
                                    });
                                }
                            }
    
                            props.history.push('/historico');

                            response.data.data.map((pdf) => {
                                window.open(pdf);
                            })

                            // window.open(response.data.data.receita_pdf);
                        }
                    }
                } else {

                    store.addNotification({
                        title: "Erro!",
                        message: `Existem prescrições incompletas! \nRevise os produtos e emita novamente!`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 6000,
                            onScreen: true
                        }
                    });
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }, 200);
                }



                setLoadingPrescription(false);
            }
        } catch (e) {

            
            if (!e.response.data.errors || e.response.data.errors === undefined) {

                store.addNotification({
                    title: "Erro!",
                    message: `Existem prescrições incompletas! \nRevise os produtos e emita novamente!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 6000,
                        onScreen: true
                    }
                });
                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }, 200);
            } else {

                setLoadingPrescription(false);
                try {
                    const error = e.response.data.errors.agro_art_id.error;
                    store.addNotification({
                        title: "Erro!",
                        message: `${error}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                    
                } catch (err) {
                    setLoadingPrescription(false);


                    if (e.response.status === 426) {
                        setShowExitValidation(true);
                        setSendPrescription(false);
                    } else {
        
                        const erro = e.response.data.errors;
                        store.addNotification({
                            title: "Erro!",
                            message: `${erro}`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 6000,
                                onScreen: true
                            }
                        });
        
                        
    
                        if (e.response.status === 403) {
                            if (values.stepTwoParams.assinatura.value === 'D') {
                                // setOpenUpload(true);
                                // setShowModalUpload(true);
                                setExistPassword(false);
                                setShowModalPassword(true);
                            }
                        } else if (e.response.status === 409) {
                            if (values.stepTwoParams.assinatura.value === 'D') {
                                setShowModalUpload(true);
                            }
                        }

                    }
                }
            }

            if (e.response.status === 423) { 
                props.history.push('/historico');
            }

        }
    }

    const setPassword = (senha) => {
        setSenha(senha);
        emitirReceita({stepOneParams, stepTwoParams, stepThreeParams});
    }

    const closeModalPassword = () => {
        setShowModalPassword(false);
    }

    const modalUploadClose = (e) => {

        if (e === undefined) {
            setShowModalUpload(false);

            store.addNotification({
                title: "Atenção!",
                message: `Para continuar, troque o tipo de assinatura!`,
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 8000,
                    onScreen: true
                }
            });

            setIsOpenStepTwo(true);

            window.scrollTo({
                top: 500,
                left: 0,
                behavior: 'smooth'
            });
        } else {
            setPassword(e);
            setShowModalUpload(false);
        }
    }
    
    const openModalUpload = () => {
        setShowModalPassword(false);
        setShowModalUpload(true);
    }

    const getCompendioParams = () => {

        if (window.location.search.slice(1).includes('cultures')) {
            setExistCompendioParams(true);

            // cultures=29;29&pests=722;2048&products=5;13&aplications=6;6&relations=18127;18473

            let c = [];
            let pst = [];
            let prod = [];
            let apli = [];
            let rel = [];
            let state = [];
            let d = [];
            let n = [];
            let a = [];
            let q = [];
            let query = window.location.search.slice(1);
            let partes = query.split('&');

            if (partes.length === 10) {
                let cultureSplit = partes[0].split('=');
                cultureSplit = cultureSplit[1].split(';');
                c = cultureSplit;

                let pestSplit = partes[1].split('=');
                pestSplit = pestSplit[1].split(';');
                pst = pestSplit;

                let prodSplit = partes[2].split('=');
                prodSplit = prodSplit[1].split(';');
                prod = prodSplit;

                let apliSplit = partes[3].split('=');
                apliSplit = apliSplit[1].split(';');
                apli = apliSplit;

                let relSplit = partes[4].split('=');
                relSplit = relSplit[1].split(';');
                rel = relSplit;
                
                let stateSplit = partes[5].split('=');
                stateSplit = stateSplit[1].split(';');
                state = stateSplit;
                
                let doseSplit = partes[6].split('=');
                doseSplit = doseSplit[1].split(';');
                d = doseSplit;
                
                let numSplit = partes[7].split('=');
                numSplit = numSplit[1].split(';');
                n = numSplit;

                let areaSplit = partes[8].split('=');
                areaSplit = areaSplit[1].split(';');
                a = areaSplit;
                
                let qtdSplit = partes[9].split('=');
                qtdSplit = qtdSplit[1].split(';');
                q = qtdSplit;


                let cParams = [];

                rel.map((r, i) => {
                    cParams = [ ...cParams, {
                        cultura: c[i],
                        praga: pst[i],
                        produto: prod[i],
                        modoAplicacao: apli[i],
                        relacaoId: rel[i],
                        estado: state[0],
                        dose: d[i],
                        numApli: n[i],
                        area: a[i],
                        quantidade: q[i]
                    }];
                })

                setCompendioParams(cParams);
                setLoadParams(true);


            } else {
                setExistCompendioParams(false);
                setLoadParams(true);
            }
        } else {
            if (window.location.search.slice(1)) {
    
                setExistCompendioParams(true);
                let query = window.location.search.slice(1);
                let partes = query.split('/');
                
                // 0 - cultura
                // 1 - praga
                // 2 - produto 
                // 3 - modo de aplicação
                // 4 - id relação
    
                setCompendioParams([
                    {
                        cultura: partes[0],
                        praga: partes[1],
                        produto: partes[2],
                        modoAplicacao: partes[3],
                        relacaoId: partes[4],
                        estado: '*'
                    }
                ]);
                setLoadParams(true);
            } else {
    
                setExistCompendioParams(false);
                setLoadParams(true);
            }
        }

    }

    const updateOpenRelations = (relations) => {
    }
    
    const upRelationsIds = (relations) => {
        let existEmptyValue = false;


        relations.map((id) => {
            if (id === 0) {
                existEmptyValue = true;
            }
        })

        setExistEmptyValues(existEmptyValue);
    }

    const getPlanConfig = () => {
        let user = JSON.parse(localStorage.getItem('@userinfo'));

        if (user && user !== null) {
            setPlanoConfig(user.contrato.agro_plan_config);
        }
    }

    const getGeolocation = () => {
        if('geolocation'in navigator){
            navigator.geolocation.getCurrentPosition(function(position){
              setLat(position.coords.latitude);
              setLng(position.coords.longitude);
            },function(error){
            })
          }else{
         }
    }

    useEffect(() => {
        validaSaldo();
        saveIp();
        getCompendioParams();
        setSubtitle(<>Dúvidas na emissão da sua receita? <a href='https://www.agroreceita.com.br/como-emitir-seu-receituario-agronomico' target='_blank'> Clique aqui e acesse o tutorial. </a> </>);
        getPlanConfig();
        getGeolocation();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIniciaVerificacao(false);
        }, 300);
    },[iniciaVerificacao]);

    useEffect(() => {
        if (sendPrescription === true) {
            setSendPrescription(false);
            emitirReceita({stepOneParams, stepTwoParams, stepThreeParams});

        }
    }, [stepOneParams, stepTwoParams, stepThreeParams, sendPrescription])

    useEffect(() => {
        validatePrescription({stepOneParams, dataEmissao, stepTwoParams, existParamsStepTwo, stepTwoError, stepThreeParams, existParamsStepThree, stepThreeError});
    },[stepOneParams, dataEmissao, stepTwoParams, existParamsStepTwo, stepTwoError, stepThreeParams, existParamsStepThree, stepThreeError, existEmptyValues])

    useEffect(() => {
        if (existPassword === true) {
            emitirReceita({stepOneParams, stepTwoParams, stepThreeParams});
        }
    },[existPassword])

    useEffect(() => {
        
        if (stepTwoParams.length !== 0 || stepTwoParams !== undefined) {
            if (stepTwoParams.erroArt === true && stepTwoParams.erroCrea === false && stepTwoParams.erroResponsavel === false && stepTwoParams.erroUser === false) {
                setMsgError('Verifique a ART/TRT!');
            } else if (stepTwoParams.erroArt === false && stepTwoParams.erroCrea === true && stepTwoParams.erroResponsavel === false && stepTwoParams.erroUser === false) {
                setMsgError('Verifique o CREA/CFTA!');
            } else if (stepTwoParams.erroArt === false && stepTwoParams.erroCrea === false && stepTwoParams.erroResponsavel === true && stepTwoParams.erroUser === false) {
                setMsgError('Verifique o responsável técnico!');
            } else if (stepTwoParams.erroArt === false && stepTwoParams.erroCrea === false && stepTwoParams.erroResponsavel === false && stepTwoParams.erroUser === true) {
                setMsgError('Verifique o usuário para envio da receita a defesa!');
            } else if (stepTwoParams.erroArt === true || stepTwoParams.erroCrea === true || stepTwoParams.erroResponsavel === true || stepTwoParams.erroUser === true) {
                setMsgError('Verifique as opções do responsável técnico!');
            } else {
                if (stepTwoError === true) {
                    if (!stepTwoParams.art) {
                        setMsgError('Verifique a ART/TRT!');
                    } else {
                        setMsgError('Verifique as opções do responsável técnico!');
                    }
                }
            }
        }


        if (stepThreeParams.length !== 0 || stepThreeParams !== undefined) {
            if (stepThreeError === true) {
                if (stepThreeParams.produtor === '' && stepThreeParams.fazenda !== '' && stepThreeParams.devolucao !== '') {
                    setMsgError('Verifique o produtor!');
                } else if (stepThreeParams.produtor !== '' && stepThreeParams.fazenda === '' && stepThreeParams.devolucao !== '') {
                    setMsgError('Verifique o local de aplicação!');
                } else if (stepThreeParams.produtor !== '' && stepThreeParams.fazenda !== '' && stepThreeParams.devolucao === '') {
                    setMsgError('Verifique o local de devolução!');
                } else if (stepThreeParams.produtor === '' || stepThreeParams.fazenda === '' || stepThreeParams.devolucao === '') {
                    setMsgError('Verifique as informações de onde o produto será aplicado!');

                }
            }
        } 


    },[stepOneParams, stepTwoParams, stepTwoError, stepThreeParams, stepThreeError]);

    return (
        <>
            <GenericUpper
                align={align}
                title={title}
                subtitle={subtitle}
                help={true}
                text={"Dúvidas na emissão da sua receita?"}
                toSearch={'https://agroreceita.com.br/como-emitir-seu-receituario-agronomico/'}
                textToSearch={'Clique aqui e acesse o tutorial!'}
            ></GenericUpper>

            {saldoReceitas > 0 && (
                <DivSaldoContainer>
                    <DivSaldo isMobile={props.isMobile}>
                        Saldo de Receitas: {saldoReceitas}
                    </DivSaldo>
                </DivSaldoContainer>

            )}

            {loadParams ? (
                <>
                    <PrescriptionStepOne
                        updateQuantidadeRelations={updateQuantidadeRelations}
                        update={(value) => updateParamsOne(value)}
                        updateErrorStepOne={(erro) => setErroStepOne(erro)}
                        updateOpenRelations={(r) => updateOpenRelations(r)}
                        updateRelationIds={(ids) => upRelationsIds(ids)}
                        isCompendio={existCompendioParams}
                        compendioParams={compendioParams}
                        countRelations={(value) => setCountRelations(value)}
                        startValidation={iniciaVerificacao}
                        enviaReceita={() => enviarReceita()}
                        maxRelations={false}
                    />
        
                    <PrescriptionStepTwo 
                        id='resp'
                        error={stepTwoError}
                        existInformation={existParamsStepTwo}
                        disabled={stepOneParams.estado === undefined || dataEmissao === '' || dataEmissao === undefined ? true : false}
                        dataEmissao={dataEmissao}
                        estado={stepOneParams.estado}
                        params={stepTwoParams}
                        update={(value) => updateParamsTwo(value)}
                        updateError={(erro) => setStepTwoError(erro)}
                        isOpen={isOpenStepTwo}
                        updateIsOpen={(isOpen) => updateIsOpenStepTwo(isOpen)}
                        //updateTourDisabled={(e)=>{props.config.updateTourDisabled02(e) ; updateIsOpenStepTwo(e) ; resetStepThree()}}
                    />
        
                    <PrescriptionStepThree 
                        error={stepThreeError}
                        existInformation={existParamsStepThree}
                        disabled={disabledStepThree}
                        estado={stepOneParams.estado}
                        params={stepThreeParams}
                        art={stepTwoParams.art}
                        crea={stepTwoParams.crea}
                        update={(value) => updateParamsThree(value)}
                        isOpen={isOpenStepThree}
                        updateIsOpen={(isOpen) => updateIsOpenStepThree(isOpen)}
                        updateError={(erro) => setStepThreeError(erro)}
                    />

                    <PrescriptionStepFour 
                        produtos={produtosSelecionados}
                        update={(nf) => setNFeInfo(nf)}
                        disabled={stepOneParams.estado ? stepOneParams.estado.value === 'GO' ? produtosSelecionados.length > 0 ? false : true : false : true}
                        estado={stepOneParams.estado ? stepOneParams.estado.value : ''}
                        erros={errosNfGo}
                    />

                    <PrescriptionStepFive
                        relations={relationsSelecionadas}
                        produtos={produtosSelecionados}
                        update={(nf) => setSiccaInfo(nf)}
                        disabled={stepOneParams.estado ? siccaInfo.estado === 'MG' ? produtosSelecionados.length > 0 ? false : true : false : true}
                        estado={stepOneParams.estado ? stepOneParams.estado.value : ''}
                    />
                    
                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                        <Button 
                            width='296px'
                            height='50px'
                            value='EMITIR RECEITA'
                            outline={false}
                            disabled={disabledFinish}
                            onClick={() => setShowModalYesNo(true)}
                            loading={loadingPrescription}
                            tooltip={true}
                            tooltipMsg={msgError}
                        />
                    </div>
        
                    {showModalYesNo && (
                        <ModalYesNo
                            show={showModalYesNo}
                            onHide={() => setShowModalYesNo(false)}
                            resposta=''
                            update={(e) => updateYesNo(e)}
                            title='Atenção!'
                            text='Tem certeza que deseja finalizar? A responsabilidade pelas informações na receita agronômica é do responsável técnico. Depois de finalizada ela não poderá ser alterada!'
                        ></ModalYesNo>
                    )}
        
                    {showModalPassword && (
                        <ModalPassword
                            text={"Precisamos da sua senha para validar a autenticidade do certificado. Para sua segurança, essa senha não ficará salva em nossos servidores."}
                            show={showModalPassword}
                            update={(e) => updatePassword(e)}
                            onHide={() => closeModalPassword()}
                            openUpload={openUpload}
                            onHideToUpload={() => openModalUpload()}
                        />
                    )}
        
                    {showModalUpload && (
                        <ModalUpload 
                            show={showModalUpload}
                            onHide={modalUploadClose}
                            cpf={stepTwoParams.responsavel.cpf}
                            updatePassword={(e) => setPassword(e)}
                        />
                    )}
                </>
            ) : (null)}

            <ModalAlert
                show={!isApto}
                size='lg'
                onHide={() => goHome()}
            >

                {planoConfig.agro_plan_id == 3 ? (
                    <>
                        <h4 style={{
                            width: '100%',
                            marginTop: '10px',
                            textAlign: 'center'
                        }}>
                            Seu limite de receitas mensal esgotou!
                        </h4>
        
                        <p style={{
                            width: '100%',
                            textAlign: 'center'
                        }}>
                            {planoConfig.is_pre_pago ? (
                                'Compre mais receitas ou atualize seu plano para continuar emitindo!'
                                ) : (
                                    <>
                                        Seu saldo de <b>{planoConfig.prescriptions_limit}</b> receitas será renovado no dia <b>{formatDate(planoConfig.date_next_renewal)}</b>. Você pode aumentar seu limite de receitas mensal ou fazer um upgrade do seu plano para continuar emitindo!
                                    </>
                            )}
                        </p>
        
                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '10px', marginBottom: '10px' }}>
                            <Button 
                                width='170px'
                                height='40px'
                                value='VER PLANOS'
                                outline={false}
                                disabled={false}
                                onClick={() => goToPlans()}
                                loading={false}
                                tooltip={true}
                                tooltipMsg={''}
                            />
        
                            <Button 
                                width='170px'
                                height='40px'
                                value='AUMENTAR LIMITE'
                                outline={false}
                                disabled={false}
                                onClick={() => goToLimit()}
                                loading={false}
                                tooltip={true}
                                tooltipMsg={''}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <h4 style={{
                            width: '100%',
                            marginTop: '10px',
                            textAlign: 'center'
                        }}>
                            Seu saldo para emitir receitas acabou!
                        </h4>

                        {planoConfig.is_pre_pago ? (
                            <p style={{
                                width: '100%',
                                textAlign: 'center'
                            }}> 
                                Compre mais receitas ou atualize seu plano para continuar emitindo!'
                            </p>
                            ) : (
                            <>
                                <p style={{
                                    width: '100%',
                                    textAlign: 'center'
                                }}> 
                                    Precisando de mais receitas? Temos uma surpresa para te contar!
                                </p>
                                <p style={{
                                    width: '100%',
                                    textAlign: 'center'
                                }}> 
                                    Assinando o AgroReceita nos próximos 14 dias você garante 10% off nas primeiras doze mensalidades.
                                </p>
                                
                                <p style={{
                                    width: '100%',
                                    textAlign: 'center'
                                }}> 
                                    Utilize o cupom <b>+RECEITAS</b> e aproveite todas as funcionalidades do AgroReceita.
                                </p>
                                <p style={{
                                    width: '100%',
                                    textAlign: 'center'
                                }}> 
                                    Conheça o plano que mais se encaixa na sua necessidade.
                                </p>
                            </>
                        )}

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '10px', marginBottom: '10px' }}>
                            <Button 
                                width='170px'
                                height='40px'
                                value='VER PLANOS'
                                outline={false}
                                disabled={false}
                                onClick={() => goToPlans()}
                                loading={false}
                                tooltip={true}
                                tooltipMsg={''}
                            />
        
                            {planoConfig.is_pre_pago ? (
                                <Button 
                                    width='170px'
                                    height='40px'
                                    value='COMPRAR RECEITAS'
                                    outline={false}
                                    disabled={false}
                                    onClick={() => goToReceitas()}
                                    loading={false}
                                    tooltip={true}
                                    tooltipMsg={''}
                                />
                            ):(null)}
                        </div>            
                    </>
                )}
            </ModalAlert>

            {showExitValidation && (
                <ModalYesNo
                    show={showExitValidation}
                    btnNo={true}
                    onHide={() => setShowExitValidation(false)}
                    resposta=''
                    update={(e) => updateYesNoDateValidate(e)}
                    title='Atenção!'
                    text='Já foram emitidas receitas para essa ART com data posterior a data de emissão informada! Deseja emitir a receita mesmo assim? O controle do número de ART/TRT pode ser comprometido!'
                ></ModalYesNo>
            )}
        
        </>
    );
};

export default withRouter(AddReceitasLote);
